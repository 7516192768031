.header {
    background-color: #343a40;
    color: #FFF;
    font-size: 1.25rem;
    padding: 20px;
}

.signout {
    font-size: 0.8em;
    float: right;
    cursor: pointer;
}