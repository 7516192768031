.graph-container {
    border: 1px solid #888888;
    margin: 20px;
    border-radius: 5px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    width: 400px;
    display: inline-block;
}

.tInput {
    width: 45px;
}

.soundValue {
    margin: 5px;
}

.meterId {
    font-size: 0.7em;
}